export const mountWistia = () => {
  if (window.Wistia) return

  const script = document.createElement('script')
  script.src = '//fast.wistia.com/assets/external/E-v1.js'
  document.body.appendChild(script)
}

export const getWistiaVideo = wistiaId => {
  let video
  if (wistiaId) {
    try {
      video = Wistia.api(wistiaId)
    } catch {
      // Ignore as this is not required
    }
  }
  return video
}
