/* eslint-disable */
import { isPendoReady } from './pendo'

export const intercomSnippet = intervalId => {
  if (isPendoReady()) {
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/y81r60cc';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    clearInterval(intervalId)
  }
}

export const loadLiveChat = intervalId => {
  if (window.Intercom && typeof window.Intercom === 'function') {
    window.Intercom('boot', {
      app_id: 'y81r60cc'
    })
    clearInterval(intervalId)
  }
}

export const updateLiveChat = () => {
  if (window.Intercom && typeof window.Intercom === 'function') {
    window.Intercom('update')
  }
}
