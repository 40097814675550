import EmptyProductImage from '../components/common/icons/svg/empty-product-image.svg'

export const imageToHttps = image_url => {
  if (image_url) {
    if (
      !image_url ||
      image_url ===
        'http://g-ecx.images-amazon.com/images/G/01/x-site/icons/no-img-sm._CB192198896_.gif' ||
      image_url === 'N/A'
    ) {
      return EmptyProductImage
    }
    const result = image_url.replace('_SL75_', '_SL150_')
    return result.replace(
      'http://ecx.images-amazon.com',
      'https://images-na.ssl-images-amazon.com'
    )
  }
  return EmptyProductImage
}
