/* eslint-disable max-classes-per-file */
import {
  QueryClient,
  QueryCache,
  MutationCache,
  hashQueryKey,
  setLogger
} from 'react-query'

import { makeRequest } from './api/helpers'
import { buildFullPath } from './build_paths'

const doNotOverrideId = '___do_not_override_id'

const isValidKey = key => {
  return typeof key === 'string' || Array.isArray(key)
}

const isObject = key => {
  return Object.prototype.toString.call(key) === '[object Object]'
}

const checkPath = path => {
  if (typeof path !== 'string') {
    throw Error('The first key must be a string')
  }

  if (path.indexOf('?') > -1) {
    throw Error(
      'The first key cannot have a query string. When passing params use an object: [path, { data: {} }]'
    )
  }
}

const makeApiRequest = async (path, params, defaultMethod) => {
  const { data, ...rest } = params || {}
  const otherParams = rest || {}
  const method = otherParams.method?.toUpperCase() || defaultMethod
  const useQueryParams = ['GET', 'DELETE'].includes(method)

  const finalPath = useQueryParams ? buildFullPath(path, data || {}) : path
  const finalParams = useQueryParams
    ? otherParams
    : { data: data || {}, ...otherParams }
  const res = await makeRequest(finalPath, { ...finalParams, method })

  if (res?.ok === false || res?.error || res?.data?.error === true) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      error: res?.error || res?.data?.error || 'Error',
      status: res.status
    })
  }

  return res
}

const selectData = res => res?.data || null

const getQueryKey = query => {
  const { queryKey } = query || {}
  return Array.isArray(queryKey) ? queryKey : [queryKey]
}

const queryApi = async query => {
  const [path, params] = getQueryKey(query)
  const paramsCopy = { ...params }

  // pageParam is used in useInfiniteQuery to control pagination
  if (query?.pageParam) {
    paramsCopy.data.page = query.pageParam
  }
  return makeApiRequest(path, paramsCopy || {}, 'GET')
}

/*
 Make sure the key is only based on resource path + data
 Extra information such as method, timeout etc shouldn't be part of the cache key
*/
const queryApiHashFn = queryKey => {
  const [path, params] = getQueryKey({ queryKey })
  return hashQueryKey(params?.data ? [path, params.data] : path)
}

/*
 Sign the functions for comparison purposes.
 Note that strict equality won't work because of race conditions and hot module replacement
*/
queryApi[doNotOverrideId] = 'queryFn'
queryApiHashFn[doNotOverrideId] = 'queryApiHashFn'

const hasValidSignature = (a, b) => {
  return a[doNotOverrideId] !== b[doNotOverrideId]
}

class CustomQueryCache extends QueryCache {
  build(client, options, state) {
    const { queryKey, queryKeyHashFn } = options

    if (queryKeyHashFn && hasValidSignature(queryKeyHashFn, queryApiHashFn)) {
      throw new Error('The query key hash function cannot be customized')
    }

    if (!isValidKey(queryKey)) {
      throw Error('The key must be a string or an array')
    }

    const [path, params] = getQueryKey(options)

    checkPath(path)

    if (params && !isObject(params)) {
      throw Error(
        'The second argument of the compound key must be an object such as { data: {} }'
      )
    }

    return super.build(client, options, state)
  }
}

class CustomMutationCache extends MutationCache {
  build(client, options, state) {
    const { mutationKey } = options
    const customOptions = { ...options }

    if (typeof mutationKey === 'string') {
      checkPath(mutationKey)

      customOptions.mutationFn = async params => {
        return makeApiRequest(mutationKey, params || {}, 'POST')
      }
    }

    return super.build(client, customOptions, state)
  }
}

const envCacheTime = process.env.REACT_APP_DEFAULT_CACHE_TIME

if (!envCacheTime) {
  throw new Error(
    'Could not find the environment variable REACT_APP_DEFAULT_CACHE_TIME'
  )
}

setLogger({
  log: window.console.log,
  warn: window.console.warn,
  error: window.console.log
})

export const queryClient = new QueryClient({
  queryCache: new CustomQueryCache(),
  mutationCache: new CustomMutationCache(),
  defaultOptions: {
    queries: {
      staleTime: Infinity, // queries will need to be revalidated manually
      retry: false, // retry is disabled by default because a lot of endpoints are slow
      queryFn: queryApi,
      queryKeyHashFn: queryApiHashFn,
      cacheTime: parseInt(envCacheTime, 10),
      select: selectData
    },
    mutations: {
      select: selectData
    }
  }
})
