// https://react.i18next.com/guides/quick-start Current version
// https://react.i18next.com/legacy-v9/step-by-step-guide More details about config
// https://react.i18next.com/guides/multiple-translation-files Lazy load translation files
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LanguageDetector } from '../helpers/localization/detector'

// json resources
import enResource from '../../locales/en-us/index'

const supportedLocales = [
  'en-US',
  'zh-Hant',
  'de-DE',
  'es-ES',
  'fr-FR',
  'it-IT',
  'hi-IN',
  'ja-JP'
]

const importLanguageChunk = async lang => {
  return (await import(`../../locales/${lang.toLowerCase()}/index`)).default
}

// translations https://www.iana.org/assignments/language-tags/language-tags.xml
// zh-Hant => Chinese,in simplified script
const resources = {
  'en-US': enResource
}

const languageDetector = new LanguageDetector()
const selectedLanguage = languageDetector.detect()

if (
  selectedLanguage !== 'en-US' &&
  supportedLocales.includes(selectedLanguage)
) {
  // gets resolved instantly after the initial chunk import
  importLanguageChunk(selectedLanguage).then(langResource => {
    resources[selectedLanguage] = langResource
  })
}

// for all options read: https://www.i18next.com/overview/configuration-options + https://react.i18next.com/latest/i18next-instance
const options = {
  resources, // Can be loaded in runtime with i18n.addResourceBundle or fetched asynchronously based on selected language
  // namespaces: [
  //   'common' // Shared translations of common files
  // ],
  lng: selectedLanguage, // language intializes with
  compatibilityJSON: 'v3', // explicitly set this to avoid breaking changes in the future https://www.i18next.com/misc/migration-guide#v20.x.x-to-v21.0.0
  fallbackLng: {
    'zh-Hant': ['en-US'],
    'es-ES': ['en-US'],
    'it-IT': ['en-US'],
    'de-DE': ['en-US'],
    'fr-FR': ['en-US'],
    'ja-JP': ['en-US'],
    'hi-IN': ['en-US']
  },
  // debug: process.env.NODE_ENV == 'development',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  // TODO: Setup process to save + alert for missing translations https://www.i18next.com/overview/configuration-options#missing-keys
  saveMissing: false, // If a translation is missing try to save it with a value?
  returnEmptyString: false, // allows empty string as valid translation
  // nsSeparator: false, // allow keys to be phrases having `:`
  // keySeparator: false, // allow keys to be phrases having `.`
  react: {
    bindI18n: 'languageChanged',
    // bindI18nStore: '',
    // transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u', 'span', 'p'],
    useSuspense: false
  }
}

i18n
  // .use(Backend) load translation using xhr -> see /public/locales learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Cache) import Cache from 'i18next-localstorage-cache'
  // .use(LanguageDetector) detect user language learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // pass the i18n instance to **react-i18next and make it available for all components**.
  .init(options)

export const setDynamicResource = (lang, langPack) => {
  // each locale file has namespaces (account, academy, tracker etc.) so we have to set translations for each namespace
  Object.keys(langPack).forEach(namespace =>
    i18n.addResourceBundle(lang, namespace, langPack[namespace])
  )
}

export const changeLanguage = async lang => {
  if (!supportedLocales.includes(lang)) {
    return
  }

  const langPack = await importLanguageChunk(lang)
  if (langPack) {
    setDynamicResource(lang, langPack)
  }

  i18n.changeLanguage(lang)
}

export const t = i18n.t.bind(i18n)

export default i18n
