import { COBALT_APP_TYPE } from '../constants/themes'

export const updatePendo = options => {
  if (window.pendo && typeof window.pendo.updateOptions === 'function') {
    window.pendo.updateOptions(options)
  }
}

export const trackPendoEvent = (event, properties = {}) => {
  if (window.pendo && typeof window.pendo.track === 'function') {
    window.pendo.track(event, properties)
  }
}

export const isPendoReady = () => {
  return !!(
    window.pendo &&
    typeof window.pendo.isReady === 'function' &&
    window.pendo.isReady()
  )
}

const guidesDisableFor = (hash, appType) => {
  return (
    appType === COBALT_APP_TYPE ||
    hash.match(
      /registrations|login|checkout|onboard|password|amazing|add-user|404-page|setup/
    )
  )
}

export const togglePendoGuides = (hash, appType) => {
  if (
    window.pendo &&
    typeof window.pendo.setGuidesDisabled === 'function' &&
    isPendoReady()
  ) {
    if (guidesDisableFor(hash, appType)) {
      window.pendo.setGuidesDisabled(true)
    } else {
      // From Pendo:
      // Note that, due to the guides originally being disabled, we block the original loading of guides.
      // You'll need to using pendo.loadGuides() after to have the guides load and display.
      window.pendo.setGuidesDisabled(false)
    }
    window.pendo.loadGuides()
    return true
  }

  return false
}

export const toggleGuideInterval = intervalRef => {
  if (togglePendoGuides(window.location.hash)) {
    clearInterval(intervalRef)
  }
}
