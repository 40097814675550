import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import createRootReducer from 'reducers'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

const initialState = {}
export const history = createHashHistory()

export const createStoreWithMiddleware = applyMiddleware(
  routerMiddleware(history),
  thunk
)(createStore)

const store = createStoreWithMiddleware(
  createRootReducer(history),
  initialState
)

export default store
