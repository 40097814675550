import { daysFromNow, getCookie, setCookie } from './cookies'

export const AMAZON_CONNECTION_SURVEY = 'Amazon Connection Survey'
export const PRODUCT_RESEARCH_SURVEY = 'Product Research Survey'
export const KEYWORD_SURVEY = 'Keyword Survey'
export const LISTING_BUILDER_SURVEY = 'Listing Builder Survey'
export const REVIEW_AUTOMATION_SURVEY = 'Review Automation Survey'
export const AD_ANALYTICS_SURVEY = 'Ad Analytics Survey'

const SURVEYS_COOKIE = {
  [AMAZON_CONNECTION_SURVEY]: 'ul_amazon_connection_survey',
  [PRODUCT_RESEARCH_SURVEY]: 'ul_product_research_survey',
  [KEYWORD_SURVEY]: 'ul_keyword_survey',
  [LISTING_BUILDER_SURVEY]: 'ul_listing_builder_survey',
  [REVIEW_AUTOMATION_SURVEY]: 'ul_review_automation_survey',
  [AD_ANALYTICS_SURVEY]: 'ul_ad_analytics_survey'
}

export const usabillaLiveInitializer = intervalId => {
  if (window.usabilla_live && typeof window.usabilla_live === 'function') {
    /**
     * We're following steps from this guide
     * https://support.usabilla.com/hc/en-us/articles/115002075889-How-do-I-manage-dependencies-between-campaigns-
     *
     * After each trigger we set the correct cookie for 90 days to prevent spamming the user with survey on every action
     * The connection between Campaign trigger and the cookie is managed in GetFeedback dashboard settings
     * usabilla_live function will not show survey based on trigger-cookie relationship and settings
     */
    window.usabilla_live(
      'setEventCallback',
      (category, action, analyticsId) => {
        const cookieOps = {
          expires: daysFromNow(90).toUTCString()
        }

        // analyticsId refers to ANALYTICS ID of the campaign in usabilla app
        // eslint-disable-next-line default-case
        switch (analyticsId) {
          case '3d8a1d850b5e':
            setCookie(SURVEYS_COOKIE[AMAZON_CONNECTION_SURVEY], 1, cookieOps)
            break
          case '591a432a90d3':
            setCookie(SURVEYS_COOKIE[PRODUCT_RESEARCH_SURVEY], 1, cookieOps)
            break
          case 'f2f444ef41cd':
            setCookie(SURVEYS_COOKIE[KEYWORD_SURVEY], 1, cookieOps)
            break
          case '9c6068125814':
            setCookie(SURVEYS_COOKIE[LISTING_BUILDER_SURVEY], 1, cookieOps)
            break
          case '35f4fab37cab':
            setCookie(SURVEYS_COOKIE[REVIEW_AUTOMATION_SURVEY], 1, cookieOps)
            break
          case '8876daa7f516':
            setCookie(SURVEYS_COOKIE[AD_ANALYTICS_SURVEY], 1, cookieOps)
            break
        }
      }
    )

    clearInterval(intervalId)
  }
}

export const isSurveyTriggered = campaignName => {
  if (window.usabilla_live && typeof window.usabilla_live === 'function') {
    const cookie = getCookie(SURVEYS_COOKIE[campaignName])
    return cookie && cookie === '1'
  }
  return false
}

export const triggerUsabillaCampaign = campaignName => {
  if (window.usabilla_live && typeof window.usabilla_live === 'function') {
    window.usabilla_live('trigger', campaignName)
  }
}
