import { getMixpanelId } from '../helpers/mixpanel'

export const setSiftSession = () => {
  // eslint-disable-next-line no-multi-assign
  const _sift = (window._sift = window._sift || [])
  _sift.push(['_setSessionId', getMixpanelId()])
}

export const mountSift = () => {
  const SIFT_KEY = process.env.REACT_APP_SIFT_PRODUCTION_KEY

  const _user_id = '' // Set to the user's ID, username, or email address, or '' if not yet known.
  // eslint-disable-next-line no-multi-assign
  const _sift = (window._sift = window._sift || [])

  _sift.push(['_setAccount', SIFT_KEY])
  _sift.push(['_setUserId', _user_id])
  _sift.push(['_trackPageview'])
  ;(function() {
    function ls() {
      const e = document.createElement('script')
      e.src = 'https://cdn.siftscience.com/s.js'
      document.body.appendChild(e)
    }
    if (window.attachEvent) {
      window.attachEvent('onload', ls)
    } else {
      window.addEventListener('load', ls, false)
    }
  })()
}
